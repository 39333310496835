@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.cdnfonts.com/css/helvetica-neue-5");

/* index.css */
@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"),
    url("./assets/fonts/HelveticaNeueRoman.otf") format("opentype");
}

*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

:root {
  /* Evergreen colors */
  --evergreen-primary-70: #550080;
  --evergreen-primary-60: #f6eef9;
  --whitish-gray: #09090905;
  --red: #da1a33;
  --yellow: #fcb155;
  --orange: #ff7849;
  --light-blue: #5090cd;
  --lime-green: #28b67a;

  /* Neutrals */
  --neutral-100: #090909;
  --neutral-90: #1d2939;
  --neutral-80: #344054;
  --neutral-70: #475467;
  --neutral-60: #667085;
  --neutral-50: #98a2b3;
  --neutral-40: #f9fffb;
  --neutral-30: #eaecf0;
  --neutral-20: #fefbffcc;
  --neutral-10: #fefbff;
  --max-width: 1440px;
}

html body {
  margin: 0;
  color: var(--neutral-0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  scroll-behavior: smooth;
  height: 100%;
  font-size: 15px;
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 400;
  line-height: 22px;
  background-color: #f3eaf8;
}

body a {
  color: inherit;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
  transition: 0.3s;
  background-color: var(--neutral-40);
}

h2 {
  color: var(--neutral-100);
  font-size: 24px;
  line-height: 29.3px;
  font-weight: 500;
  text-transform: uppercase;
}
button,
input,
textarea {
  font-family: "Inter", sans-serif;
  outline: none !important;
}

button,
[role="button"] {
  cursor: pointer;
}

.container {
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: var(--max-width);
  margin: auto;
}
/* width and height */
::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 5px;
}
::-webkit-scrollbar-track:hover {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--evergreen-primary-70);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--evergreen-primary-70);
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
  display: none; For Chrome and Safari 
} */

.heroHome {
  background-image: url(./assets/images/heroImage.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 95% top;
  min-height: 100vh;
  height: 150vh;
}
.glowing-underline {
  color: var(--evergreen-primary-70);
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-flex;
}

ul {
  list-style: disc;
}

@media screen and (min-width: 1024px) {
  .glowing-underline {
    font-size: 12px;
  }
  h2 {
    font-size: 48px;
    line-height: 58.61px;
  }

  .heroHome {
    background-position: center;
  }
}
